import React from 'react';
import { LoginRegister } from '@/common/components/login-register';
import { jsx as ___EmotionJSX } from "@emotion/core";

var RegisterPage = function RegisterPage() {
  return ___EmotionJSX(LoginRegister, {
    title: "register",
    isRegister: true
  });
};

RegisterPage.displayName = "RegisterPage";
export default RegisterPage;